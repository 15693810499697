//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      plan: true,
      plan2: true,
      leftEntry: { label: "سنوي", value: "annually" },
      rightEntry: { label: "شهري", value: "monthly" },
      value: false,
      value2: false,
    };
  },
  watch: {
    value() {
      this.value ? this.plan = this.rightEntry.value : this.plan = this.leftEntry.value
    },
    value2() {
      this.value2 ? this.plan2 = this.rightEntry.value : this.plan2 = this.leftEntry.value
    }
  },

};
